import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, Resource } from 'types/schema';
import InnerWrap from 'components/layout/InnerWrap';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import PagePictureTitle from 'components/layout/PagePictureTitle';
import ResourceGrid from 'components/resources/ResourceGrid';

export const pageQuery = graphql`
  query ResourcesPageQuery {
    posts: allSanityResource(
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          ...ResourcePreview
        }
      }
    }
    sanityResourcesPage(id: { eq: "-748ffed0-ed5f-581b-b4a4-474a6920bf88" }) {
      meta {
        title
        description
      }
      pageTitle
    }
  }
`;

type Props = {
  data: {
    posts: {
      edges: {
        node: Resource;
      }[];
    };
    sanityResourcesPage: BaseSanityPage;
  };
};

const ResourcesPage: React.FC<Props> = ({ data }) => {
  const resources = data.posts.edges;
  const {
    meta: { title, description },
    pageTitle,
  } = data.sanityResourcesPage;
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/resources/' }}>
      <PagePictureTitle
        title={pageTitle}
        pictureUrl="https://images.unsplash.com/photo-1447023029226-ef8f6b52e3ea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
        overlayProps={{ opacity: 0.7 }}
      />
      <OuterWrap>
        <InnerWrap>
          <ResourceGrid resources={resources} />
        </InnerWrap>
      </OuterWrap>
    </MainLayout>
  );
};

export default ResourcesPage;
